<template>
  <div class="mt-1">
    <!-- Table Container Card -->
    <b-overlay :show="loading" rounded="sm" no-fade>
      <validation-observer ref="contactRules" tag="form">
        <div>
          <b-card>
            <b-card-title class="mb-2 bigTitle">Customer Information</b-card-title>
            <b-row>
              <b-col v-if="formData.id" cols="12" sm="6" md="6" xl="6">
                <b-form-group label="Date Generated" label-for="dateGenerated">
                  <flat-pickr placeholder="Select Date" id="dateGenerated" v-model="formData.created_at" class="form-control" :config="{ dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1 } }" style="background-color: white;" />
                </b-form-group>
              </b-col>

              <b-col v-else cols="12" sm="6" md="6" xl="6">
                <b-form-group label="Payment Option" label-for="paymentOption">
                  <v-select
                    v-model="formData.type"
                    id="type"
                    :options="[
                      { name: 'Charge String (Internal Customer)', type: 'internal' },
                      { name: 'PO Number (External Customer)', type: 'external' },
                    ]"
                    :reduce="(val) => val.type"
                    label="name"
                    :clearable="false"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="6" md="6" xl="6">
                <b-form-group v-if="formData.id" label="Job Number" label-for="jobNumber">
                  <b-form-input type="text" disabled :value="'#' + formData.id" />
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="6" md="6" xl="6">
                <b-form-group label="Customer Name*" label-for="contact">
                  <validation-provider #default="{ errors }" name="Customer Name" rules="required">
                    <!-- <b-form-input type="text" v-model="formData.contact" list="my-list-id" class="form-control" />
                    <datalist id="my-list-id" class="custom-datalist">
                      <option v-for="customer in customerList" :key="customer.id">{{ customer.contact }}</option>
                    </datalist> -->

                    <div class="dropdown">
                      <b-form-input type="text" v-model="formData.contact" @change="handleContactSelection" list="my-list-id" class="form-control" />
                      <!-- <datalist id="my-list-id" class="custom-datalist">
                        <option v-for="customer in customerList" :key="customer.id">{{ customer.contact }}</option>
                      </datalist> -->
                    </div>

                    <small class="text-danger text-left">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="6" md="6" xl="6">
                <b-form-group label="Customer Email" label-for="contactEmail">
                  <validation-provider #default="{ errors }" name="Customer Email" rules="required">
                    <b-form-input type="text" v-model="formData.contactEmail" />
                    <small class="text-danger text-left">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="Customer Address" label-for="companyAddress">
                  <b-form-textarea type="text" v-model="formData.companyAddress"></b-form-textarea>
                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group label="Customer Phone" label-for="contactPhone">
                    <b-form-input type="text" v-model="formData.contactPhone" />
             
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="6" md="6" xl="6">
                <b-form-group label="Department" label-for="department">
                    <b-form-input type="text" v-model="formData.department" />
          
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="6" md="6" xl="6">
                <b-form-group label="Lab" label-for="lab">
                  <b-form-input type="text" v-model="formData.lab"  list="my-lab-list" class="form-control" />

                      <datalist id="my-lab-list" class="custom-datalist">
                        <option v-for="(lab,index) in labList" :key="index">{{ lab.name }}</option>
                      </datalist>

                </b-form-group>
              </b-col>

              <b-col cols="12">
                <b-form-group :label="formData.type == 'external' ? 'PO Number' : 'Charge String'" label-for="digCode">
                  <div v-if="formData.type == 'external'">
                    <b-form-input type="text" v-model="formData.digCode" />
                  </div>

                  <b-row v-else>
                    <div class="col-sm-12 col-md-3 mb-1">
                      <b-form-input type="number" v-model="tubCode" placeholder="Tub" />
                    </div>
                    <div class="col-sm-12 col-md-3 mb-1">
                      <b-form-input type="number" v-model="orgCode" placeholder="Org" />
                    </div>
                    <div class="col-sm-12 col-md-6 mb-1">
                      <!-- <b-form-select v-model="objectCode" :options="objectCodes" value-field="code" text-field="description" placeholder="Object Code">
                        <template v-slot:option="{ option }">
                          <span>{{ option.code }} - {{ option.description }}</span>
                        </template>
                      </b-form-select> -->

                      <select v-model="objectCode" class="form-control" placeholder="Object Code">
                        <option value="" disabled selected>Select Object Code</option>

                        <option v-for="item in objectCodes" :key="item.code" :value="item.code"> {{ item.code }} - {{ item.description }} </option>
                      </select>
                    </div>
                    <div class="col-sm-12 col-md-3 mb-1">
                      <b-form-input type="number" v-model="fundCode" placeholder="Fund" />
                    </div>
                    <div class="col-sm-12 col-md-3 mb-1">
                      <b-form-input type="number" v-model="activityCode" placeholder="Activity" />
                    </div>
                    <div class="col-sm-12 col-md-3 mb-1">
                      <b-form-input type="number" v-model="subActivityCode" placeholder="Sub Activity" />
                    </div>
                    <div class="col-sm-12 col-md-3 mb-1">
                      <b-form-input type="number" v-model="rootCode" placeholder="Root" />
                    </div>

                    <div class="col-12 text-left mb-1"><strong>Charge String Code :</strong> {{ tubCode || '###' }}-{{ orgCode || '#####' }}-{{ objectCode || '####' }}-{{ fundCode || '######' }}-{{ activityCode || '######' }}-{{ subActivityCode || '####' }}-{{ rootCode || '#####' }}</div>
                  </b-row>
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="3" md="3" xl="3">
                <b-form-group label="Tag Number" label-for="tagNumber">
                
                      <b-form-input type="number" v-model="formData.tagNumber" placeholder="Tag Number" />

                </b-form-group>
              </b-col>
              <b-col cols="12" sm="9" md="9" xl="9">
                
              </b-col>




              <b-col cols="12" sm="6" md="6" xl="6">
                <b-form-group label="Principal Investigator" label-for="principalInvestigator">
                    <b-form-input type="text" v-model="formData.principalInvestigator" />
               
                </b-form-group>
              </b-col>

              <b-col cols="12" sm="6" md="6" xl="6">
                <b-form-group label="Miscellaneous Keywords" label-for="misc">
                  <b-form-input type="text" v-model="formData.misc" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

          <b-card>
            <b-row>
              <b-col>
                <b-form-group label="Project Name" label-for="projectName">
                  <b-form-input type="text" v-model="formData.projectName" />
                </b-form-group>
              </b-col>
            </b-row>

            <b-card class="bg-info" v-for="(part, partIndex) in formData.projectValues" :key="partIndex">
              <b-card-title class="mb-2 bigTitle">Part #{{ part.partNumber }}</b-card-title>
              <b-row>
                <div class="d-flex align-items-center justify-content-end">
                  <button type="button" v-if="partIndex > 0" class="remove-part-button" @click="removePart(partIndex)">X</button>
                </div>

                <b-col cols="12" sm="3" md="3" xl="3">
                  <b-form-group label="Part Number" label-for="partNumber">
                    <b-form-input type="text" v-model="part.partNumber" />
                  </b-form-group>
                </b-col>

                <b-col cols="12" sm="3" md="3" xl="3">
                  <b-form-group label="Qty" label-for="qty">
                    <b-form-input type="text" v-model="part.qty" />
                  </b-form-group>
                </b-col>

                <b-col cols="12" sm="3" md="3" xl="3">
                  <b-form-group label="Part Name" label-for="partName">
                      <b-form-input type="text" v-model="part.partName" />
                
                  </b-form-group>
                </b-col>

                <b-col cols="12" sm="3" md="3" xl="3">
                  <b-form-group label="Revision" label-for="revision">
                    <b-form-input type="text" v-model="part.revision" />
                  </b-form-group>
                </b-col>

                <b-col cols="12" sm="6" md="6" xl="6">
                  <b-form-group label="Material" label-for="material">
                    <!-- <v-select v-model="part.material" id="material" @input="changeMaterial(part)" :options="materials" :reduce="(val) => val.id" label="name" :clearable="false" /> -->
                    <v-select id="material" :taggable="true" :loading="dropdownLoading" @option:created="createMaterial($event,part)" append-to-body v-model="part.material" @input="changeMaterial(part)" :reduce="(val) => val.id" label="name" :options="materials"></v-select>

                  </b-form-group>
                </b-col>

                <b-col cols="12" sm="3" md="3" xl="3">
                  <b-form-group label="Want by" label-for="wantBy">
                    <flat-pickr placeholder="Select Date" id="wantBy" v-model="part.wantBy" class="form-control" :config="{ dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1 } }" style="background-color: white;" />
                  </b-form-group>
                </b-col>

                <b-col cols="12" sm="3" md="3" xl="3">
                  <b-form-group label="Need By" label-for="needBy">
                    <flat-pickr placeholder="Select Date" id="needBy" v-model="part.needBy" class="form-control" :config="{ dateFormat: 'Y-m-d', locale: { firstDayOfWeek: 1 } }" style="background-color: white;" />
                  </b-form-group>
                </b-col>

                <b-col cols="12" sm="3" md="3" xl="3">
                  <b-form-group label="OV Process Required?" label-for="ovProcess">
                    <div class="d-flex">
                      <b-form-checkbox v-model="part.ovProcess" name="ovProcess" value="Yes" class="mr-2">Yes</b-form-checkbox>
                      <b-form-checkbox v-model="part.ovProcess" name="ovProcess" value="No">No</b-form-checkbox>
                    </div>
                  </b-form-group>
                </b-col>

                <b-col cols="12" sm="3" md="3" xl="3">
                  <b-form-group label="Made before?" label-for="madeBefore">
                    <div class="d-flex">
                      <b-form-checkbox v-model="part.madeBefore" name="madeBefore" value="Yes" class="mr-2">Yes</b-form-checkbox>
                      <b-form-checkbox v-model="part.madeBefore" name="madeBefore" value="No">No</b-form-checkbox>
                    </div>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-row>
                    <div class="col-md-12">
                      <label>Supporting documents <span class="required">*</span></label>
                      <div class="custom-file">
                        <input type="file" class="custom-file-input" id="customFile" ref="fileInput" multiple @change="handleFileChange($event, partIndex)" accept=".pdf, .step, .dxf" />
                        <label class="custom-file-label" for="customFile">Choose a .pdf, .step, or .dxf file...</label>
                      </div>
                    </div>
                  </b-row>

                  <b-row>
                    <div class="form-row p-2 text-center" style="justify-content: center">
                      <div v-for="(file, index) in part.customFiles" :key="index" class="file-preview" v-show="file.type !== 'delete'">
                        <div>
                          <img :src="require('@/assets/images/' + file.type + '.png')" class="file-image" />

                          <button type="button" @click="removeFile(partIndex, index)" class="remove-button">X</button>
                        </div>

                        <div class="file-info">
                          <span class="file-name">{{ truncateFileName(file.fileName, 12) }}</span>
                        </div>
                      </div>
                    </div>
                  </b-row>

                  <div v-if="loading" class="text-center">
                    <div class="spinner-border text-primary" role="status">
                      <span class="sr-only">Loading...</span>
                    </div>
                    <p>Uploading...</p>
                  </div>
                </b-col>

                <b-col cols="12" sm="12" md="12" xl="12">
                  <b-form-group label="Notes" label-for="notes">
                    <b-form-textarea v-model="part.notes" placeholder="Add Part Notes" rows="4" style="width: 100%;"></b-form-textarea>
                  </b-form-group>
                </b-col>

              </b-row>
            </b-card>
            <b-row>
              <b-col>
                <div class="d-flex align-items-center justify-content-center">
                  <b-button @click="addNewFormSection" variant="primary">
                    Add New Item
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </b-card>

          <b-card v-if="formData != null">
            <b-card-title class="mb-2 bigTitle">Notes</b-card-title>
            <b-row>
              <b-col>
                <b-form-textarea v-model="formData.notes" placeholder="Add Project Notes" rows="4" style="width: 100%;"></b-form-textarea>
              </b-col>
            </b-row>
          </b-card>

          <b-row v-if="formData != null" style="height: 150px;">
            <b-col class="text-right">
              <b-button v-if="formData.id != null" @click="updateForm" variant="primary">
                Save Changes
              </b-button>
              <b-button v-else @click="submitForm" variant="primary">
                Save Project
              </b-button>
            </b-col>
          </b-row>
        </div>
      </validation-observer>
    </b-overlay>
  </div>
</template>

<script>
import { BOverlay, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown, BDropdownItem, BPagination, BFormRating } from 'bootstrap-vue';
import store from '@/store';
import { onUnmounted } from '@vue/composition-api';
import projectStoreModule from './projectStoreModule';
import vSelect from 'vue-select';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import flatPickr from 'vue-flatpickr-component';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email } from '@validations';
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    ToastificationContent,
    flatPickr,
  },

  setup() {
    const FORM_APP_STORE_MODULE_NAME = 'project';
    // Register module
    if (!store.hasModule(FORM_APP_STORE_MODULE_NAME)) store.registerModule(FORM_APP_STORE_MODULE_NAME, projectStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FORM_APP_STORE_MODULE_NAME)) store.unregisterModule(FORM_APP_STORE_MODULE_NAME);
    });
  },

  data() {
    return {
      dropdownLoading:false,
      projectId: null,
      formData: {
        created_at: null,
        id: null,
        contactEmail: null,
        contact: null,
        companyAddress: null,
        contactPhone: null,
        department: null,
        lab: null,
        digCode: null,
        type: 'internal',
        principalInvestigator: null,
        misc: null,
        projectName: null,
        tagNumber:null,
        projectValues: [
          {
            partName: null,
            qty: null,
            partNumber: null,
            revision: null,
            material: null,
            materialName: null,
            madeBefore: null,
            wantBy: null,
            needBy: null,
            ovProcess: null,
            customFiles: [],
            x: null,
            y: null,
            z: null,
          },
        ],
        notes: null,
      },
      objectCodes: [],
      customerList: [],
      labList: [],
      tubCode: null,
      orgCode: null,
      objectCode: null,
      fundCode: null,
      activityCode: null,
      subActivityCode: null,
      rootCode: null,
      loading: true,
      baseURL: store.state.app.baseURL,
      materials: [],
    };
  },

  watch: {},

  methods: {
    handleContactSelection() {
      // const selectedContact = this.formData.contact;

      // const contactExists = this.customerList.find((customer) => customer.contact === selectedContact);
      // console.log(contactExists);
      // if (contactExists) {
      //   this.formData.contactEmail = contactExists.contactEmail;
      //   this.formData.companyAddress = contactExists.companyAddress;
      //   this.formData.contactPhone = contactExists.contactPhone;
      //   this.formData.department = contactExists.department;
      //   this.formData.lab = contactExists.lab;
      //   this.formData.principalInvestigator = contactExists.principalInvestigator;
      //   this.formData.misc = contactExists.misc;

      //   if (contactExists && contactExists.digCode) {
      //     this.tubCode = contactExists.digCode.substring(0, 3);
      //     this.orgCode = contactExists.digCode.substring(3, 8);
      //     this.objectCode = contactExists.digCode.substring(8, 12);
      //     this.fundCode = contactExists.digCode.substring(12, 18);
      //     this.activityCode = contactExists.digCode.substring(18, 24);
      //     this.subActivityCode = contactExists.digCode.substring(24, 28);
      //     this.rootCode = contactExists.digCode.substring(28, 33);
      //   } else {
      //     this.tubCode = null;
      //     this.orgCode = null;
      //     this.objectCode = null;
      //     this.fundCode = null;
      //     this.activityCode = null;
      //     this.subActivityCode = null;
      //     this.rootCode = null;
      //   }
      // } else {
      //   this.formData.contactEmail = null;
      //   this.formData.companyAddress = null;
      //   this.formData.contactPhone = null;
      //   this.formData.department = null;
      //   this.formData.lab = null;
      //   this.formData.digCode = null;
      //   this.formData.principalInvestigator = null;
      //   this.formData.misc = null;
      //   this.formData.tagNumber = null;

      //   this.tubCode = null;
      //   this.orgCode = null;
      //   this.objectCode = null;
      //   this.fundCode = null;
      //   this.activityCode = null;
      //   this.subActivityCode = null;
      //   this.rootCode = null;
      // }

      this.formData.contactEmail = null;
        this.formData.companyAddress = null;
        this.formData.contactPhone = null;
        this.formData.department = null;
        this.formData.lab = null;
        this.formData.digCode = null;
        this.formData.principalInvestigator = null;
        this.formData.misc = null;
        this.formData.tagNumber = null;

        this.tubCode = null;
        this.orgCode = null;
        this.objectCode = null;
        this.fundCode = null;
        this.activityCode = null;
        this.subActivityCode = null;
        this.rootCode = null;

    },

    truncateFileName(fileName, maxLength) {
      const truncatedName = fileName.length > maxLength ? fileName.slice(0, maxLength).toLowerCase() + '...' : fileName.toLowerCase();
      return truncatedName;
    },

    changeMaterial(part) {
      if(!this.dropdownLoading){
        let newMaterial = this.materials.find((e) => e.id == part.material);
      part.materialName = newMaterial['name'];
      }
  
    },
    createMaterial(item,part) {


    this.dropdownLoading = true;

    store
          .dispatch('project/saveMaterial', item)
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Successful',
                text: '✔️ Material Add Successful',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            });

            this.materials.push(
              response.data
        );
        part.material = response.data.id;
        this.changeMaterial(part);
        this.dropdownLoading = false;

          })
          .catch((error) => {
            this.loading = false;
            console.log(error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'An error occurred',
                text: 'Please try again later or contact support.',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            });
          });
          this.loading = false;

    },



    generateQuote() {
      this.$router.push({ name: 'generate-quote' });
    },

    submitForm() {
      this.loading = true;
      this.$refs.contactRules.validate().then((success) => {
        if (success) {
         

        
              this.formData.projectValues.forEach((project) => {
              project.materialName = this.findMaterialAdditionals(project.material)?.name || '';
              });

              this.saveFormNoMail();

        
        } else {
          this.loading = false;
          this.$swal({
            icon: 'warning',
            title: 'Please try again.',
            text: 'Please fill in the required fields.',
          });
        }
      });
    },

    saveForm() {
      if (this.formData.type == 'internal') {
        if (this.tubCode || this.orgCode || this.objectCode || this.fundCode || this.activityCode || this.subActivityCode || this.rootCode) {
          this.formData.digCode = (this.tubCode || '') + (this.orgCode || '') + (this.objectCode || '') + (this.fundCode || '') + (this.activityCode || '') + (this.subActivityCode || '') + (this.rootCode || '');
        } else {
          this.formData.digCode = null;
        }
      }
      store
        .dispatch('project/saveForm', this.formData)
        .then((res) => {
          const applicationId = res.data.id;

          if (!applicationId) {
            this.loading = false;
            this.$swal({
              icon: 'error',
              title: 'An Error Occurred',
              text: 'An error occurred. Please try again.',
            });
            return;
          }

          this.uploadFiles(applicationId);
        })
        .catch((error) => {
          this.loading = false;
          this.$swal({
            icon: 'error',
            title: 'An Error Occurred',
            text: 'An error occurred. Please try again.',
          });
        });
    },


    
    saveFormNoMail() {
      if (this.formData.type == 'internal') {
        if (this.tubCode || this.orgCode || this.objectCode || this.fundCode || this.activityCode || this.subActivityCode || this.rootCode) {
          this.formData.digCode = (this.tubCode || '') + (this.orgCode || '') + (this.objectCode || '') + (this.fundCode || '') + (this.activityCode || '') + (this.subActivityCode || '') + (this.rootCode || '');
        } else {
          this.formData.digCode = null;
        }
      }
      store
        .dispatch('project/saveFormNoMail', this.formData)
        .then((res) => {
          const applicationId = res.data.id;

          if (!applicationId) {
            this.loading = false;
            this.$swal({
              icon: 'error',
              title: 'An Error Occurred',
              text: 'An error occurred. Please try again.',
            });
            return;
          }

          this.uploadFiles(applicationId);
        })
        .catch((error) => {
          this.loading = false;
          this.$swal({
            icon: 'error',
            title: 'An Error Occurred',
            text: 'An error occurred. Please try again.',
          });
        });
    },

    updateForm() {
      if (this.formData.type == 'internal') {
        console.log(this.tubCode, this.orgCode, this.objectCode, this.fundCode, this.activityCode, this.subActivityCode, this.rootCode);
        if (this.tubCode || this.orgCode || this.objectCode || this.fundCode || this.activityCode || this.subActivityCode || this.rootCode) {
          this.formData.digCode = (this.tubCode || '') + (this.orgCode || '') + (this.objectCode || '') + (this.fundCode || '') + (this.activityCode || '') + (this.subActivityCode || '') + (this.rootCode || '');
        } else {
          this.formData.digCode = null;
        }
      }
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to update the project data?', {
          size: 'sm',
          title: 'Update Project',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;

            store
              .dispatch('project/updateForm', this.formData)
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ Project has been updated successfully',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });
                this.uploadFiles(this.formData.id);
              })
              .catch((error) => {
                console.log(error);
                this.loading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Please try again later or contact support.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
              });
          }
        });
    },

    uploadFiles(applicationId) {
      var fileCheck = 0;
      this.formData.projectValues.forEach((element) => {
        if (element.customFiles.length > 0) {
          fileCheck = 1;
        }
      });

      if (fileCheck == 0) {
        this.loading = false;
        router.push({ name: 'project-preview', params: { id: applicationId } });
        return;
      }

      const uploadPromises = [];

      for (let i = 0; i < this.formData.projectValues.length; i++) {
        const part = this.formData.projectValues[i];

        if (part.customFiles && part.customFiles.length > 0) {
          part.customFiles.forEach((fileObject) => {
            const formData = new FormData();
            formData.append('customFile', fileObject.file);
            formData.append('applicationId', applicationId);
            formData.append('formIndex', i);

            const uploadPromise = store.dispatch('project/uploadFormFile', formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            });

            uploadPromises.push(uploadPromise);
          });
        }
      }

      Promise.all(uploadPromises)
        .then((responses) => {
          this.loading = false;
          router.push({ name: 'project-preview', params: { id: applicationId } });
        })
        .catch((error) => {
          this.loading = false;
          Swal.fire({
            icon: 'error',
            title: 'An Error Occurred',
            text: 'An error occurred. Please try again.',
          });
        });
    },

    junkQuote() {
      this.$bvModal
        .msgBoxConfirm('Are you sure you want to move project to archive?', {
          size: 'sm',
          title: 'Confirm Move to Archive',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.loading = true;

            store
              .dispatch('project/junkForm', this.formData.id)
              .then((response) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Successful',
                    text: '✔️ Project successfully moved to junk',
                    icon: 'ThumbsUpIcon',
                    variant: 'success',
                  },
                });

                this.$router.push({ name: 'project-list' });
              })
              .catch((error) => {
                console.log(error);
                this.loading = false;
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'An error occurred',
                    text: 'Please try again later or contact support.',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                });
              });
          }
        });
    },

    getForms() {
      this.loading = true;

      this.projectId = parseInt(router.currentRoute.params.id);

      store
        .dispatch('project/getFormById', { id: router.currentRoute.params.id })
        .then((res) => {
          this.formData = res.data;

          if (this.formData.digCode) {
            this.tubCode = this.formData.digCode.substring(0, 3);
            this.orgCode = this.formData.digCode.substring(3, 8);
            this.objectCode = this.formData.digCode.substring(8, 12);
            this.fundCode = this.formData.digCode.substring(12, 18);
            this.activityCode = this.formData.digCode.substring(18, 24);
            this.subActivityCode = this.formData.digCode.substring(24, 28);
            this.rootCode = this.formData.digCode.substring(28, 33);
          } else {
            this.tubCode = null;
            this.orgCode = null;
            this.objectCode = null;
            this.fundCode = null;
            this.activityCode = null;
            this.subActivityCode = null;
            this.rootCode = null;
          }
          this.getFormData();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    getFormData() {
      store
        .dispatch('project/getFormData')
        .then((res) => {
          this.materials = res.data.materials;
          this.objectCodes = res.data.objectCodes;
          this.customerList = res.data.customers;
          this.labList = res.data.labs;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'An error occurred',
              text: 'Please try again later or contact support.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        });
    },

    downloadFile(fileId, fileName) {
      const swalWithLoading = this.$swal.mixin({
        title: 'Downloading...',
        text: 'Please wait while the file is being downloaded.',
        onOpen: () => {
          swalWithLoading.showLoading();

          store
            .dispatch('project/formFileDownload', { id: fileId })
            .then((res) => {
              const url = window.URL.createObjectURL(new Blob([res.data]));
              const link = document.createElement('a');
              link.href = url;

              link.setAttribute('download', fileName);
              document.body.appendChild(link);
              link.click();

              this.simulateFileDownload();
            })
            .catch((error) => {
              console.log(error);
              this.loading = false;
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'An error occurred',
                  text: 'Please try again later or contact support.',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              });
            });
        },
      });

      swalWithLoading.fire();
    },

    simulateFileDownload() {
      setTimeout(() => {
        this.$swal('Download Completed!', 'Your file has been successfully downloaded.', 'success');
      }, 200);
    },
    addNewFormSection() {
      this.formData.projectValues.push({
        partName: null,
        qty: null,
        partNumber: null,
        revision: null,
        material: null,
        materialName: null,
        madeBefore: null,
        wantBy: null,
        needBy: null,
        ovProcess: null,
        customFiles: [],
        x: null,
        y: null,
        z: null,
      });
    },
    removePart(index) {
      this.formData.projectValues.splice(index, 1);
    },
    handleFileChange(e, index) {
      const files = e.target.files;
      const customFiles = this.formData.projectValues[index].customFiles;

      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileType = this.getFileType(file.name);

        if (fileType === 'unsupported') {
          this.$swal({
            icon: 'warning',
            title: 'Unsupported File Type',
            text: 'Only .pdf, .step, and .dxf files are supported.',
          });
          continue;
        }
        const fileObject = {
          type: fileType,
          file: file,
          fileName: file.name,
        };
        customFiles.push(fileObject);
      }
    },

    removeFile(partIndex, fileIndex) {
      this.formData.projectValues[partIndex].customFiles.splice(fileIndex, 1);
    },

    getFileType(fileName) {
      const extension = fileName
        .split('.')
        .pop()
        .toLowerCase();
      switch (extension) {
        case 'pdf':
          return 'pdf';
        case 'stp':
        case 'step':
          return 'step';
        case 'dxf':
          return 'dxf';
        default:
          return 'unsupported';
      }
    },
    findMaterialAdditionals(id) {
      return this.materials.find((material) => material.id === id);
    },
  },

  created() {
    if (router.currentRoute.params.id) {
      this.getForms();
    } else {
      this.getFormData();
    }
  },
  beforeRouteLeave(to, from, next) {
    console.log(to)
    if(to.name!='project-preview'){
      this.$swal({
    icon: 'warning',
    title: 'Unsaved Changes',
    text: 'This data will be lost if you leave without saving. Cancel, then save first.',
    showCancelButton: true,
    confirmButtonText: 'Leave without saving',
    cancelButtonText: 'Cancel',
    cancelButtonColor:'#82868b',
    confirmButtonColor:'#a51c30',
    customClass: {

      confirmButton: 'confirm-button-swal',
      cancelButton: 'your-cancel-button-class',
    }

  }).then((result) => {
    if (result.isConfirmed) {
      next(); // Continue with the route change
    } else {
      next(false); // Prevent the route change
    }
  });

    }
    else{
      next(); // Continue with the route change
    }
  
},



  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style scoped>
.form-row {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: 10px;
}

.remove-part-button {
  position: absolute;
  top: 3px;
  right: 10px;
  background-color: #a51c30;
  border: none;
  color: white;
  font-size: 11px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
}

.file-preview {
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
}

.file-image {
  width: 100px;
  height: 100px;
  border: 1px solid #ccc;
  max-width: 100%;
}
.file-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.remove-button {
  position: absolute;
  top: -10px;
  right: -10px;
  background-color: transparent;
  border: none;
  color: white;
  background-color: #a51c30;
  font-size: 0.8rem;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.2s;
}

.file-preview:hover .remove-button {
  opacity: 1;
}

.confirm-button-swal{
  margin-right:100px;
  font-size: smaller;
}



</style>
